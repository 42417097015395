module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-P7T5CL2","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Wigwam","short_name":"Wigwam","start_url":"/","background_color":"#0d0e1d","theme_color":"#0d0e1d","display":"minimal-ui","icon":"docs/logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3451d551b4b58d80747e281ee6fdb484"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
