/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import "./src/css/index.scss";

import { globalHistory } from "@reach/router";
import AOS from "aos";

initAos();
globalHistory.listen(initAos);

function initAos() {
  AOS.init({
    once: true,
    disable: "phone",
    duration: 600,
    easing: "ease-out-sine",
  });
}

disableOutlinesForClick();

/**
 * A nifty little class that maintains event handlers to add a class
 * to the container element when entering "mouse mode" (on a `mousedown` event)
 * and remove it when entering "keyboard mode" (on a `tab` key `keydown` event)
 */
const TAB_KEY_CODE = 9;
const CLASS_NAME = "focus-disabled";

function disableOutlinesForClick() {
  const container = document.documentElement;
  container.addEventListener("mousedown", handleMouseDown);

  return reset;

  function handleMouseDown() {
    reset();
    container.classList.add(CLASS_NAME);
    container.addEventListener("keydown", handleKeyDown);
  }

  function handleKeyDown(evt) {
    if (evt.which === TAB_KEY_CODE) {
      reset();
      container.addEventListener("mousedown", handleMouseDown);
    }
  }

  function reset() {
    container.classList.remove(CLASS_NAME);
    container.removeEventListener("keydown", handleKeyDown);
    container.removeEventListener("mousedown", handleMouseDown);
  }
}
